import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/app/apps/diamond-chest/src/components/AccountMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/app/apps/diamond-chest/src/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWrapper"] */ "/app/apps/diamond-chest/src/components/feedback/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/app/apps/diamond-chest/src/components/LanguageSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntlProvider"] */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/app/apps/diamond-chest/src/providers/feature-flags-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionLogoutHandler"] */ "/app/apps/diamond-chest/src/providers/SessionLogoutHandler.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.2.4_babel-plugin-react-compiler@19.0.0-beta-e993439-202_2b3b9c0768a5b97ff21643d7f00800fc/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@4.0.2_next@15.2.4_babel-plugin-react-compiler@19.0.0-beta-e993439-20250328_re_e16f714ab1101708760118deb90ba301/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_babel-plugin-react-compiler@19.0.0-beta-e993439-20250328_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../TeleNeoVariable.woff2\",\"display\":\"swap\",\"variable\":\"--font-teleneo\"}],\"variableName\":\"TeleNeo\"}");
